<template>
    <layout-full-width :title="$tc('notification', 2)">

        <component-actions :action-filters="[]"
                           :itemsPerPage="10"
                           :page="1"
                           :primary-actions="primaryActions"
                           :total="1"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @paginate="onChangePage"
                           @search="onSearch"/>

        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">
                                <font-awesome-icon :icon="['far', 'square']"/>
                            </th>
                            <th scope="col">{{ $tc('event', 1) }}</th>
                            <th scope="col">{{ $t('active') }}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <font-awesome-icon :icon="['far', 'square']"/>
                            </td>
                            <td>{{ $t('notifications.event.campaign-recipients-submitted') }}</td>
                            <td>
                                <div class="form-check form-switch">
                                    <input id="event-1" checked class="form-check-input" disabled type="checkbox">
                                    <label class="form-check-label sr-only" for="event-1">{{
                                            $t('notifications.event.campaign-recipients-submitted')
                                        }}</label>
                                </div>
                            </td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentActions from "@/components/ui/ComponentActions";

export default {
    name: "PageNotifications",
    components: {ComponentActions, LayoutFullWidth},
    data() {
        return {
            primaryActions: [
                {
                    text: this.$t('actions.new'),
                    icon: 'plus',
                    type: 'modal',
                    disabled: true,
                    target: ''
                }
            ]
        }
    },
    computed: {
        selectAll() {
            return false;
        }
    },
    methods: {
        onChangePage() {
            console.log('onChangePage');
        },
        onSearch() {
            console.log('onSearch');
        },
        onChangeItemsPerPage() {
            console.log('onChangeItemsPerPage');
        },
        onApplyFilters() {
            console.log('onApplyFilters');
        }
    }
}
</script>

<style lang="scss" scoped></style>